import { atom } from "jotai"
import { proxy } from "valtio"

const promptResponse = atom();

const homeState = proxy({
    selectedPromptImage: null,
    selectedProduct: null,
    selectedCategory: null,
    activeStep: 0
})

const homeAction = {
    setActiveStep: (val) => (homeState.activeStep = val),
    setSelectedPromptImage: (val) => (homeState.selectedPromptImage = val),
    setSelectedProduct: (val) => (homeState.selectedProduct = val),
    setSelectedCategory: (val) => (homeState.selectedCategory = val)
}

export { homeState, homeAction, promptResponse }
